
<div class="row m-2 m-md-0 justify-content-between mt-0" >
    <!-- <button color='accent' class=" btn  col-5 col-md-12 listbutton " mat-raised-button  (click)="switchHF(false)"[ngClass]="{'selected': !toggleHF}">
        <img src="../../../assets/img/flightw.svg" alt="" width="50" height="50"*ngIf="toggleHF">
        <img src="../../../assets/img/flight.svg" alt="" width="50" height="50" *ngIf="!toggleHF">  
     </button> -->
    <!-- <button color='accent' class=" btn col-5 col-md-12 listbutton " mat-raised-button (click)="switchHF(true)" [ngClass]="{'selected': toggleHF}">  
        <img src="../../../assets/img/hotelIconw.svg" alt="" width="50" height="50"*ngIf="!toggleHF">
        <img src="../../../assets/img/hotelIcon.svg" alt="" width="50" height="50"*ngIf="toggleHF">       
    </button> -->
   
</div>

