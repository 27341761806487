//angular Imports Start//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, mergeMap, retry, take } from 'rxjs/operators';

//angular import End //
//Imported Module start//
import { SearchFlightModule } from '../models/search-flight/search-flight.module';
import { FlightsInfoModule } from '../models/flights-info/flights-info.module';
import { CitiesModule } from '../models/cities/cities.module';
import { PointOfsaleModule } from '../models/point-ofsale/point-ofsale.module';
import { CurrencyModule } from '../models/currency/currency.module';
import { EventEmitter } from '@angular/core';
import { PassengersModule } from '../models/passengers/passengers.module';
import { CountriescodeModule } from '../models/countriescode/countriescode.module';
import { FlightSearchResult } from '../flight-search-result';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Bestoffers } from '../interface/bestoffers';
import { FareRules } from '../interface/fare-rules';
import { HotelsCitiesModule } from '../models/hotels-cities/hotels-cities.module';
import { SearchHoteltModule } from '../models/search-hotel/search-hotel.module';
import { GetHotelModule } from './../models/search-hotel/get-hotels.module';
import { RoomNum } from '../models/HotelRooms/RoomNum';
import { RequiredHotel } from '../models/HotelBooking/RequiredHotelData';
import { HotelRoom, Room } from '../models/HotelRooms/HotelRooms';
import { familymember } from '../Components/users/family-members/family-members.component';
import { Cobon } from '../interface/cobon';
import { OfflineServiceModule } from '../models/offline-service/offline-service.module';
import { Observable } from 'rxjs';
// import { familymember } from '../Components/users/family-members/family-members.component';
// import { OfflineServiceModule } from '../models/offline-service/offline-service.module';
export interface guest {
  adult: number;
  child: number[];
}
export interface ipA {
  ip:string
}
//Imported Module send //
@Injectable({
  providedIn: 'root'
})
export class MyApiService {
  myData: BehaviorSubject<Bestoffers> = new BehaviorSubject(null);
  flitvalidaty: any;
  offers: Bestoffers;
  resultUpdate: EventEmitter<any> = new EventEmitter();
  pointofsale: EventEmitter<PointOfsaleModule> = new EventEmitter();
  selectedFlight: EventEmitter<FlightSearchResult> = new EventEmitter();
  countriescode: CountriescodeModule[] = [];
  cityLoader:boolean = false

  constructor(private httpClient: HttpClient) { }

  //*** flight search function *** //
  // example api:
  // http://localhost:8080/flights/flightsSearch{Api}/en{language}/KWD{Currency}/Eg{pointcode}/oneway{type}/KWI-CAI-March%2015,%202019/A-1-C-0-I-0/Economy/False/all/0/0/Direct?searchID=test5

  searchResult: any;
  // normal search
  searchflow: string = "https://flightsearch.khaleejgate.com";
  BookingFlow: string = "https://flightflow.khaleejgate.com";
  FareRules: string = "https://flightprov.khaleejgate.com";
  // searchflow: string = '192.168.1.30:8055';
  // BookingFlow: string = '192.168.1.30:8066';
  // FareRules: string = '192.168.1.30:8021';
  // asm: string = '46.166.160.65:7060';
  // http://backofficeapi.mesafer.com/
  asm: string = "https://backofficeapi.khaleejgate.com";

  // hotels: string = "http://hotelsapi.mesafer.com/";
  Apihotels: string = "https://hotelsapi.travellii.com";
  backOffice =  'https://backofficeapi.rhlaty.com';
  hotelprepay: string = "https://prepayapi.khaleejgate.com";
  users: string = "https://usersapi.khaleejgate.com";
  admin: string = "https://adminapi.khaleejgate.com";
  getDPayment: string = 'http://49.12.83.231:7045/';
  // ApisaveBooking:string='192.168.1.104:3030';
  // hotels:string ="192.168.1.106:3030";
  //  meta search ports
  // searchflow:string ='46.166.160.65:8005';
  // BookingFlow:string = '46.166.160.65:8035';
  // FareRules:string ='46.166.160.65:8025';
  // asm:string = '46.166.160.65:7060';
  searchFlight(searchFlight: SearchFlightModule) {
    let language = searchFlight.lan;
    let currency = searchFlight.Currency;
    let SearchPoint = searchFlight.pointOfReservation;
    let flightType = searchFlight.flightType;
    let flightInfo = searchFlight.flightsInfo;
    let searchId = searchFlight.serachId;
    let passengers = searchFlight.passengers;
    let Cclass = searchFlight.Cclass;
    let directOnly = searchFlight.showDirect;
    // !!!dont add spaces between the constracted Api below
    let api: string = `${this.searchflow}/flights/flightsSearch/${language}/${currency}/${SearchPoint}/${flightType}/${flightInfo}/${passengers}/${Cclass}/${directOnly}/all/0/0/Direct?searchID=${searchId}`;
    return this.httpClient.get<FlightSearchResult>(api).pipe(
      take(1),
      map(
        (res) => {
          this.searchResult = res;
          console.log("result", this.searchResult, api);
          this.resultUpdate.emit(this.searchResult); return res;
        }
      )
    )
  }


  getHotelsCities(key: string) {
    let api = `${this.Apihotels}/api/City?city=${key}`;
    return this.httpClient.get<HotelsCitiesModule[]>(api).pipe(take(1))
  }
  getCountries(lang: string) {
    let api = `${this.backOffice}/api/GetAllCountriesByLangName?LangCode=${lang}`;
    return this.httpClient.get<CountriescodeModule[]>(api).pipe( retry(2),take(1),catchError(err=>{console.log(err);throw err})
    );
  }

  passingerFormatter(array: any[]) {
    // convert array of passanger type number to A-1-C-0-I-0
    let passengersString: string;
    passengersString = 'A-' + array[0] + '-C-' + array[1] + '-I-' + array[2];
    return passengersString;
  }


  flightInfoFormatter(array: FlightsInfoModule[]) {
    //return string of flights in KWI-CAI-March%2015%202019_ format
    let FlightsInfoArray: string = '';
    for (let element of array) {
      let fligt: string = element.departingCity + '-' + element.arrivalCity + '-' + element.departionDate + '_';
      FlightsInfoArray = FlightsInfoArray + fligt;
    }
    return FlightsInfoArray.slice(0, -1);
  }

  cityData(lang: string) {
    //return cities data
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    let api = `${this.asm}/api/GetSearchFlowMapping?LangCode=${lang}`;
    let result: any;
    let errorOut: any;
    return this.httpClient.get<CitiesModule[]>(api, { headers: headers })
  }

  pointOfSale() :Observable<PointOfsaleModule> {
    
    //return point of sale 
    // let api = "https://ipapi.co/json";
    let api = "https://api.ipify.org/?format=json";
     return this.httpClient.get<ipA>(api).pipe(
      take(1),
      mergeMap(result=> 
        this.httpClient.get<PointOfsaleModule>(`https://ipapi.co/${result.ip}/json/`)
        )
        // this.pointofsale.emit(result);
       
      )
    ;
  }

  currencyApi(currency: string) {
    //return currency
    let api = `${this.admin}/api/CurrencyApi?currency=${currency}`;

    return this.httpClient.get<CurrencyModule[]>(api).pipe(
      take(1),
      map((result) => {

        return result;
      })
    )
  }

  getSelectedFlight(searchid: string, sequenceNum: number, providerKey: string) {
    //retern selected flight
    debugger
    let api = `${this.searchflow}/api/GetSelectedFlight?searchid=${searchid}&SequenceNum=${sequenceNum}&PKey=${providerKey}`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => {
          this.selectedFlight.emit(result), console.log(result);
        })
    )
  }

  saveBooking(searchid: string, sequenceNum: number, body: PassengersModule, pkey: string) {
    //take passengers information and return HGNu number
    let api = `${this.BookingFlow}/api/SaveBooking?SearchId=${searchid}&SeqNum=${sequenceNum}&PKey=${pkey}`;
    console.log(api);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }

  countrycode(lang: string) {
    //  take language and return contries and countries codes
    debugger
    lang = lang.toUpperCase();
    let api = `${this.asm}/api/GetAllCountriesByLangName?LangCode=${lang}`;
    return this.httpClient.get<CountriescodeModule[]>(api).pipe(
      take(1),
      map(
        (result) => {

          this.countriescode = result;
          return result;
        }
      )
    )
  }

  CheckFlightValidation(HGNu: string, lang: string, searchid: string, SeqNum: number, pkey: string) {
    // check if the flight is still available
    let api = `${this.BookingFlow}/api/CheckFlightValidation?HGNum=${HGNu}&Language=${lang}&SearchId=${searchid}&SeqNum=${SeqNum}&PKey=${pkey}`;
    console.log("checkapi", api);
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { this.flitvalidaty = result; return result; }
      )
    )
  }

  GetPaymentView(
    ip: string,
    iplocation: string,
    HGNu: string,
    searchid: string,
    productId: any,
    selectedServvices: any[]
  ) {
    //return payment link//
    let api = `${this.BookingFlow}/api/GetPaymentView?IP=${ip}&IPLoc=${iplocation}&HG=${HGNu}&SId=${searchid}&NotifyToken=`;
    console.log(api, selectedServvices);
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    let body = {
      UserSeletedInsurance: { ProductId: productId },
      UserSeletedServices: { SeletedServicesCodes: selectedServvices },
    };
    return this.httpClient
      .post<any>(api, body, {
        headers,
      })
      .pipe(
        take(1),
        map((result) => {
          console.log(result);
          return result;
        })
      );
  }

  findinsurance(HGNu: string, searchid: string) {
    // return selected insurance
    let api = `${this.BookingFlow}/api/Insurance?HG=${HGNu}&SearchId=${searchid}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => {
          return result;
          console.log("insurance called")
        }
      )
    )
  }
  // offlineServices(SID:string){
  //   //return array of services
  //   let api = `${this.BookingFlow}/api/GetOfflineServices?SID=${SID}`
  //   return this.httpClient.get<OfflineServiceModule[]>(api).pipe(
  //     take(1),
  //     map(
  //       (result) => { return result; }
  //     )
  //   )
  // }
  PostProcessing(HGNu: string, searchid: string, tok: string, url: string) {
    //  get satus after succesful payment
    let api = `${url}&tok=${tok}`;
    console.log('this is tok', tok);
    console.log("this is api", api);
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }

  getConfirmation(HGNu: string, searchid: string) {
    // get return conformtion from the clint
    let api = `${this.BookingFlow}/api/BookingConfirmation?HG=${HGNu}&SId=${searchid}`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(
      take(3),
      map(
        (result) => { return result; }
      )
    )
  }
  getPaymentResult(url: string) {
    // get the payment result status
    let api = `${this.hotelprepay}api/paymentresult?${url}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  getOffers(pointOfsale: string) {
    //get ofers in the selected point of sale
    let api = `${this.searchflow}/api/GetOffers?POS=${pointOfsale}`;

    return this.httpClient.get<Bestoffers>(api).pipe(
      take(1),
      map(
        (result) => { console.log('why me', result); if (result) { this.myData.next(result) }; return result; }
      )
    )
  }

  OfferBooking(body: object) {
    //take passengers information and return HGNu number
    let api = `${this.searchflow}/api/SelectOffer `;
    console.log(api);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  fareRules(sid: string, seq: number, pKey: string) {
    //get fare rules for each flight
    let api = `${this.FareRules}/api/GetFareRules?SId=${sid}&SeqNum=${seq}&PKey=${pKey}`;
    console.log(api);

    return this.httpClient.get<FareRules[]>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  offlineServices(SID: string) {
    //return array of services
    let api = `${this.BookingFlow}/api/GetOfflineServices?SID=${SID}`;
    console.log(api);
    return this.httpClient.get<OfflineServiceModule[]>(api).pipe(
      take(1),
      map((result) => {
        return result;
      })
    );
  }
  activateCobon(promo: string, Sid: string, sequenceNum: any, pkey: string) {
    //check the validity of cobon and return new price
    let api = `${this.BookingFlow}/api/GetPromotionDetails?PromoCode=${promo}&SearchId=${Sid}&SeqNum=${sequenceNum}&PKey=${pkey}  `;
    return this.httpClient.get<Cobon>(api).pipe(take(1));
  }

  getHotels(searchHotel: SearchHoteltModule) {


    let CityName = searchHotel.CityName;
    let DateFrom = searchHotel.checkIn;
    let DateTo = searchHotel.checkOut;
    let currency = searchHotel.Currency;
    let Nat = searchHotel.nation;
    let POS = searchHotel.POS;
    let Source = "Direct";
    let sID = searchHotel.serachId;
    let Lang = searchHotel.lan;
    let guestInfo = searchHotel.guestInfo;
    let SearchRooms: guest[] = [];

    // R0A3C2G4G3R1A2C0R2A1C1G3
    // str.split('-')[1];
    let arr = guestInfo.split("R");
    arr.splice(0, 1);
    //  getSearchRooms();
    let room: guest = { adult: 1, child: [0] };

    for (let i = 0; i < arr.length; i++) {

      let chNum: number = 0;
      let age: number[] = [];
      chNum = Number(arr[i].slice(4, 5));
      console.log("child", typeof chNum, chNum);
      if (chNum === 0) {
        age = [];
        console.log(chNum, "0child")
      }
      if (chNum === 1) {
        age = [];
        console.log(chNum, "1child")
        age.push(Number(arr[i].slice(6, 7)));
      }
      if (chNum === 2) {
        console.log(chNum, "2child")
        age = [];
        age.push(Number(arr[i].slice(6, 7)));
        age.push(Number(arr[i].slice(8, 9)));
        console.log(age, "age")
      }

      SearchRooms[i] = { adult: Number(arr[i].slice(2, 3)), child: age };


    }

    console.log("allRooms", SearchRooms);


    let getHotels: GetHotelModule = new GetHotelModule(CityName, DateFrom, DateTo, currency, Nat, POS, Source, sID, Lang, SearchRooms);
    let api = `${this.Apihotels}/api/HotelSearch`;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<any>(api, getHotels, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => {
          console.log(result);
          this.resultUpdate.emit(result)
          return result
        }
      )
    )
  }



  //new 

  // get direct payment
  getDirectPayment(searchid: string, tok: string, BNu: string) {
    let api = `${this.getDPayment}/api/DirectPayment?sid=${searchid}&token=${tok}&bookingNumber=${BNu}`;
    return this.httpClient.get<any>(api).pipe(take(3),
      map(
        (result) => {
          console.log("apidirect", api);
          return result;
        }
      )
    )
  }

  GetHotelRooms(Pid, sid, hotelID) {
    // RETURN hOTELS ROOM
    let apiHotel = `${this.Apihotels}/`;// salama1472019446 /37094/4
    console.log(apiHotel, 'myapi');
    return this.httpClient.get<HotelRoom>(apiHotel + "api/HotelRooms?sid=" + sid + "&hotel=" + hotelID + "&Pid=" + Pid).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    );
  }
  GetHotelRoomsNUM(sid, hotelID, Pid, rooms) {
    // RETURN hOTELS ROOM
    let apiHotel = `${this.Apihotels}/`;// salama1472019446 /37094/4
    return this.httpClient.get<RequiredHotel>(apiHotel + "api/HotelRooms?sid=" + sid + "&hotel=" + hotelID + "&Pid=" + Pid + "&rooms=" + rooms).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    );
  }
  HotelSaveBookingData(data) {
    // RETURN hOTELS ROOM
    //  let apiHotel=`http://192.168.1.102:3030/`;// salama1472019446 /37094/4
    let apiHotel = `${this.Apihotels}/`;

    return this.httpClient.post<any>(apiHotel + "api/HotelBooking", data).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    );

  }
  GetHPaymentView(ip: string, iplocation: string, HGNu: string, searchid: string, lang: string) {
    //return payment link for hotels payment//
    // let api = `http://${this.BookingFlow}/api/GetPaymentView?IP=${ip}&IPLoc=${iplocation}&HG=${HGNu}&SId=${searchid}&NotifyToken=`;
    let api = `${this.Apihotels}/api/PaymentView?bookingnum=${HGNu}&sid=${searchid}&ip=${ip}&Pos=${iplocation}&lang=${lang}&NotificationTok=""`;
    console.log(api);
    //  let headers = new HttpHeaders();
    //  headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //  let body= {"UserSeletedInsurance": {"ProductId":productId} };
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }

  getHPaymentResult(url: string) {
    // get the payment result status for hotels
    let api = `${this.hotelprepay}api/paymentresult?${url}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }

  PostProcessingH(HGNu: string, searchid: string, tok: string, url: string) {
    //  get satus after succesful payment
    let api = `${this.Apihotels}/Api/ConfirmHotelStatus?sid=${searchid}&bookingNum=${HGNu}&tok=${tok}`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  getConfirmationH(HGNu: string, searchid: string) {
    // get return conformtion from the clint
    let api = `${this.Apihotels}/Api/Confirmation?sid=${searchid}&bookingNum=${HGNu}`;
    return this.httpClient.get<any>(api).pipe(
      take(3),
      map(
        (result) => { return result; }
      )
    )
  }
  gethotelAmenities(id: any) {
    // return hotel amenities by hotel is
    let api = `${this.Apihotels}/api/getAmenities?id=${id}`;
    return this.httpClient.get<any[]>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  roomcancelation(sid: string, hotelcode: any, roomindex: any, PId: any) {
    // return room cancelation roles
    let api = `${this.Apihotels}/api/getcancelpolicy?sid=${sid}&hotelcode=${hotelcode}&roomindex=${roomindex}&PId=${PId}`;
    return this.httpClient.get<any[]>(api).pipe(
      take(2),
      map(
        (result) => { return result; }
      )
    )
  }

  // users api 
  login(body: object) {
    //login api
    let api = `${this.users}/api/Account/Login`
    // let api = `http://46.166.160.65:7099/api/Account/Login`
    console.log(api);
    console.log(body, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  signup(body: object) {
    // sign up
    let api = `${this.users}/api/Account/Register`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(body, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  forgetPassword(email) {
    // forget password
    let api = `${this.users}/api/Account/ForgotPassword`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(email, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, email, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  UpdatePassword(email) {
    //change password
    let api = `${this.users}/api/Account/ChangePassword`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(email, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, email, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  UpcomingAndHistory(email: string) {
    //get upcoming and hestory data;
    // let api = `${this.BookingFlow}/api/HistoryAndUpcomingFlights?Email=${email}`;
    let api = `${this.BookingFlow}/api/HistoryAndUpcomingFlights?Email=${email}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  UpcomingAndHistoryH(email: string) {
    //get upcoming and hestory data;
    // let api = `${this.BookingFlow}/api/HistoryAndUpcomingFlights?Email=${email}`;
    let api = `${this.Apihotels}/Api/UpcomingAndHistory?mail=${email}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  logoutapi() {
    // for user logout
    let api = `${this.users}/api/Account/Logout`;
    console.log(api);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )

  }

  userupdate(body: object) {
    //edit user account
    let api = `${this.users}/api/Account/Update?Isbase=1`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(body, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }

  // user family member Apis 
  getFamilymembers(userid: string) {
    // return all users family members registered data
    let api = `${this.admin}/api/FamilyMembersApi?customerId=${userid}`
    return this.httpClient.get<familymember[]>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  updateFamilymembers(body: object) {
    // updatefamily members registered data
    let api = `${this.admin}/api/FamilyMembersApi`
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.put<any>(api, body, {
      headers
    }).pipe(
      take(2),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  addFamilymember(body: object) {
    // add family member to existing user
    let api = `${this.admin}/api/FamilyMembersApi`
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(2),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  deletFamilymember(userid: any, memberid: any) {
    // delete famely member from exitting user
    let api = `${this.admin}/api/FamilyMembersApi?id=${memberid}&custId=${userid}`
    return this.httpClient.delete<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  getuserinfo() {
    // return all users family members registered data
    let api = `${this.users}/api/Account/UserInfo`
    console.log(api)
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result; }
      )
    )
  }

}